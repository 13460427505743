export const PATHNAME = {
    LOGIN: "/login",
    ROOT: "/",
    REGISTER: "/register",
    MANAGER_BRANCH: "/manager/branch",
    MANAGER_STAFF: "/manager/staff",
    MANAGER_PERMISSION: "/manager/permission",
    CREATE_STAFF: "manager/staff/create",
    CHANGE_PASSWORD: "changepassword",
    Collaborators: "/manager/collaborators",
    Customer: "/customer",
    Partner: "/manager/partner",
    CARD: "/cards",
    ThuChi: "/collect-expense",
    ConfigCollectExpense: "/config-collect-expense",
    Pos: "/manager/pos",
    Contract: "/contract",
    Debt: "/debt",
    Report: "/report",
    PostManager: "/manager/pospartner",
    Warning: "/warning",
    DebtCompare: "/debt/generalcomparison",
    ReportAllShop: "/report/reportallshop",
    Company: "https://ag.cardpro.ai/",
}