import { all, call, delay, put, takeEvery } from "redux-saga/effects";
import ApiUser from "../../services/user";
import { toastError, toastSuccess } from "../../utils/toast";
import actions, { IParamsUser } from "./actions";

function* getUser(payload: any) {
  try {
    //@ts-ignore
    const response = yield call(ApiUser.getUsers, payload.params);
    if (response.data.Result === 1) {
      // yield delay(1000);
      yield put(actions.getUsersSuccess(response.data));
    } else {
      toastError(response.data.Message);
      yield put(actions.getUsersError());
    }
  } catch (error) {
    // yield delay(1000);
    yield put(actions.getUsersError());
    // toastError("Có lỗi xảy ra! Vui lòng liên hệ Admin");
  }
}

function* createUser(payload: any) {
  try {
    //@ts-ignore
    const response = yield call(ApiUser.addOrUpdateUser, payload.body);
    if (response.data.Result === 1) {
      // yield delay(1000);
      yield put(actions.createUserSuccess(response.data));
      toastSuccess(payload.message);
      yield put(payload.callback());
    } else {
      toastError(response.data.Message);
      yield put(actions.createUserError());
    }
  } catch (error) {
    // yield delay(1000);
    yield put(actions.createUserError());
    // toastError("Có lỗi xảy ra! Vui lòng liên hệ Admin");
  }
}

function* deleteUser(payload: any) {
  try {
    //@ts-ignore
    const response = yield call(ApiUser.deleteUser, payload.userID);
    if (response.data.Result === 1) {
      // yield delay(1000);
      yield put(actions.deleteUserSuccess(response.data));
      toastSuccess("Xóa nhân viên thành công");
    } else {
      toastError(response.data.Message);
      yield put(actions.deleteUserError());
    }
  } catch (error) {
    console.log(error);
    yield put(actions.deleteUserError());
  }
}

export default function* rootSaga() {
  //@ts-ignore
  yield all([takeEvery(actions.GET_USERS, getUser)]);
  yield all([takeEvery(actions.CREATE_USER, createUser)]);
  //@ts-ignore
  yield all([takeEvery(actions.DELETE_USER, deleteUser)]);
}
