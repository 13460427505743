import moment from "moment"

export const getUser = () => {
    const user = localStorage.getItem('user')
    if (user) {
        return JSON.parse(user)
    } else {
        return null
    }
}

export const getShop = () => {
    const shop = localStorage.getItem('shop')
    if (shop) {
        return JSON.parse(shop)
    } else {
        return null
    }
}

export const formatDDMMYYY = (date: any) => {
    if (date) {
        return moment(date).format("DD/MM/YYYY")
    }
    return "Invalid date"
}

export const formatHHmm = (date: any) => {
    if (date) {
        return moment(date).format("HH:mm")
    }
    return "Invalid date"
}

export const formatMMYYY = (date: any) => {
    if (date) {
        return moment(date).format("MM/YYYY")
    }
    return "Invalid date"
}

export const parserNumber = (val: any) => {
    if (!val) return 0;
    return Number.parseFloat(val.replace(/\$\s?|(\.*)/g, "").replace(/(\,{1})/g, ".")).toFixed(2)
}

export const formatDDMMYYYHHmm = (date: any) => {
    if (date) {
        return moment(date).format("DD/MM/YYYY HH:mm")
    }
    return "Invalid date"
}