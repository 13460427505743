import { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Login from "../pages/login/Login";
// pages
// ----------------------------------------------------------------------

interface Props {
  children: any;
}

export default function AuthGuard({ children }: Props) {
  const token = localStorage.getItem("token");
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );

  if (!token) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
