import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://api.cardpro.ai/",
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

export default axiosInstance;
