import actions from "./actions";

const initState = {
  isLoading: false,
  data: [],
  total: 0,
};

export default function userReducer(state = initState, action: any) {
  switch (action.type) {
    case actions.GET_USERS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.result.Data,
        total: action.result.Total,
      };
    case actions.GET_USERS_ERROR:
      return {
        ...state,
        isLoading: false,
        data: [],
        total: 0,
      };
    case actions.CREATE_USER:
      return {
        ...state,
        isLoading: true,
      };
    case actions.CREATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actions.CREATE_USER_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case actions.DELETE_USER:
      return {
        ...state,
        isLoading: true,
      };
    case actions.DELETE_USER_SUCCESS:
      const newData = state.data.filter(
        (item: any) => item.UserID !== action.result.Data
      );
      return {
        ...state,
        isLoading: false,
        data: newData,
      };
    case actions.DELETE_USER_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
