export interface IParamsUser {
  UserName: string;
  Status: number;
  GroupID: number;
  PageIndex: number;
  PageSize: number;
}

export interface IBodyStaff {
  UserID: number;
  UserName: string;
  Password: string;
  FullName: string;
  Phone: string;
  Note: string;
  Status: number;
  GroupID: number;
}

const userActions = {
  GET_USERS: "GET_USERS",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_ERROR: "GET_USERS_ERROR",

  CREATE_USER: "CREATE_USER",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_ERROR: "CREATE_USER_ERROR",

  UPDATE_USER: "UPDATE_USER",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_ERROR: "UPDATE_USER_ERROR",

  DELETE_USER: "DELETE_USER",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_ERROR: "DELETE_USER_ERROR",

  getUsers: (params: IParamsUser) => ({
    type: userActions.GET_USERS,
    params,
  }),
  getUsersSuccess: (result: any) => ({
    type: userActions.GET_USERS_SUCCESS,
    result,
  }),
  getUsersError: () => ({
    type: userActions.GET_USERS_ERROR,
  }),

  createUser: (body: IBodyStaff, callback: any, message: string) => ({
    type: userActions.CREATE_USER,
    body,
    callback,
    message,
  }),
  createUserSuccess: (result: any) => ({
    type: userActions.CREATE_USER_SUCCESS,
    result,
  }),
  createUserError: () => ({
    type: userActions.CREATE_USER_ERROR,
  }),

  updateUser: (body: IBodyStaff) => ({
    type: userActions.UPDATE_USER,
    body,
  }),
  updateUserSuccess: (result: any) => ({
    type: userActions.UPDATE_USER_SUCCESS,
    result,
  }),
  updateUserError: () => ({
    type: userActions.UPDATE_USER_ERROR,
  }),

  deleteUser: (userID: number) => ({
    type: userActions.DELETE_USER,
    userID,
  }),
  deleteUserSuccess: (result: any) => ({
    type: userActions.DELETE_USER_SUCCESS,
    result,
  }),
  deleteUserError: () => ({
    type: userActions.DELETE_USER_ERROR,
  }),
};

export default userActions;
