import { all, takeEvery, put, call } from "redux-saga/effects";
import { UserService } from "../../services";
import Api from "../../services/api";
import { toastError, toastSuccess } from "../../utils/toast";
import actions from "./actions";

//@ts-ignore
function* login({ body, navigate }) {
  try {
    //@ts-ignore
    const response = yield call(Api.login, body);
    if (response.data.Result === 1) {
      localStorage.setItem("token", response.data.Data.Token);
      localStorage.setItem("user", JSON.stringify(response.data.Data));
      localStorage.setItem(
        "shop",
        JSON.stringify({
          ShopID: response.data.Data?.ShopID,
          ShopName: response.data.Data?.ShopName,
        })
      );
      yield put(actions.loginSuccess(response.data.Data));
      navigate("/", { replace: true });
    } else {
      toastError(response.data.Message);
    }
  } catch (error) {
    toastError("Có lỗi xảy ra! Vui lòng liên hệ Admin");
  }
}

function* changePassword(payload: any) {
  try {
    //@ts-ignore
    const response = yield call(Api.changePassword, payload.body);
    if (response.data.Result === 1) {
      localStorage.clear();
      yield put(actions.changePasswordSuccess(response.data.Data));
      toastSuccess("Đổi mật khẩu thành công");
      payload.navigate("/login", { replace: true });
    } else {
      toastError(response.data.Message);
    }
  } catch (error) {
    toastError("Có lỗi xảy ra! Vui lòng liên hệ Admin");
  }
}

function* register(payload: any) {
  try {
    //@ts-ignore
    const response = yield call(UserService.register, payload.body);
    if (response.data.Result === 1) {
      localStorage.clear();
      yield put(actions.registerSuccess(response.data.Data));
      toastSuccess("Đăng ký thành công");
      payload.navigate("/login", { replace: true });
    } else {
      toastError(response.data.Message);
    }
  } catch (error) {
    toastError("Có lỗi xảy ra! Vui lòng liên hệ Admin");
  }
}

export default function* rootSaga() {
  //@ts-ignore
  yield all([takeEvery(actions.LOGIN, login)]);
  yield all([takeEvery(actions.CHANGE_PASSWORD, changePassword)]);
  yield all([takeEvery(actions.REGISTER, register)]);
}
