import { IBodyLogin } from "../../interfaces";

export interface IBodyRegister {
  Username: string;
  Password: string;
  FullName: string;
  Phone: string;
  Address: string;
  ShopName: string;
}

export interface IBodyChangePassword {
  OldPassword: string;
  NewPassword: string;
}

const authActions = {
  CHECK_AUTH: "CHECK_AUTH",
  LOGIN: "LOGIN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",

  REGISTER: "REGISTER",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_ERROR: "REGISTER_ERROR",

  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_ERROR: "CHANGE_PASSWORD_ERROR",

  checkAuth: (user: any) => ({
    type: authActions.CHECK_AUTH,
    user,
  }),

  login: (body: IBodyLogin, navigate: any) => ({
    type: authActions.LOGIN,
    body,
    navigate,
  }),
  loginSuccess: (result: any) => ({
    type: authActions.LOGIN_SUCCESS,
    result,
  }),
  loginError: () => ({
    type: authActions.LOGIN_ERROR,
  }),

  register: (body: IBodyRegister, navigate: any) => ({
    type: authActions.REGISTER,
    body,
    navigate,
  }),
  registerSuccess: (result: any) => ({
    type: authActions.REGISTER_SUCCESS,
    result,
  }),
  registerError: () => ({
    type: authActions.REGISTER_ERROR,
  }),

  changePassword: (body: IBodyChangePassword, navigate: any) => ({
    type: authActions.CHANGE_PASSWORD,
    body,
    navigate,
  }),
  changePasswordSuccess: (result: any) => ({
    type: authActions.CHANGE_PASSWORD_SUCCESS,
    result,
  }),
  changePasswordError: () => ({
    type: authActions.CHANGE_PASSWORD_ERROR,
  }),
};
export default authActions;
